@import "../style-mixins";

$root: '.app';

.app {
  $containerPadding: 10px;

  @extend %app;

  &-themes {
    @extend %app;

    flex-direction: column;
  }

  .theme--dark {
    position: absolute;
    top: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity $appThemeTransitionTime;
  }

  &--theme-dark {
    .theme--dark {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &-theme-toggle-container {
    @extend %app-container;

    position: absolute;
    padding-top: $containerPadding;
  }

  &-theme-toggle {
    display:flex;
    justify-content: flex-end;
  }
}

%app {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
}
