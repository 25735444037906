@import '../../style-mixins.scss';

$iconSize: 65px;
$gridMargin: 10px;

.posts {
  position: relative;
  width: 100%;

  &-icon {
    background-size: contain;
    width: 65px;
    height: 65px;
    margin-right: $gridMargin;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-post {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: $gridMargin;
    opacity: 1;
    transition: opacity $appThemeTransitionTime;

    @media (hover: hover) {
      &:hover {
        opacity: 0.75;
      }
    }
  }

  &-info {
    overflow: hidden;
    max-height: $iconSize - ($gridMargin * 2);
    padding: $gridMargin ($gridMargin * 2);
    display: flex;
    justify-content: flex-start;
    flex: 1;
    align-items: center;
    font-size: 1.10rem;
    line-height: 1.2;
    border-radius: 0 $iconSize $iconSize 0;
  }

  &--theme-light {
    @extend .posts;

    a { 
      color: theme-color('light', 'posts-color');
    }

    & .posts-info {
      background-color: theme-color('light', 'posts-background-color');
    }
    
    .github { background-image: url('../../assets/light-theme/icons/github.png'); }
    .instagram { background-image: url('../../assets/light-theme/icons/instagram.png'); }
    .medium { background-image: url('../../assets/light-theme/icons/medium.png'); }
    .linkedin { background-image: url('../../assets/light-theme/icons/linkedin.png'); }
    .twitter { background-image: url('../../assets/light-theme/icons/twitter.png'); }
    .youtube { background-image: url('../../assets/light-theme/icons/youtube.png'); }
  }

  .post {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  &--theme-dark {
    @extend .posts;

    a {
      color: theme-color('dark', 'posts-color');
    }

    & .posts-info {
      background-color: theme-color('dark', 'posts-background-color');
    }

    .github { background-image: url('../../assets/dark-theme/icons/github.png'); }
    .instagram { background-image: url('../../assets/dark-theme/icons/instagram.png'); }
    .medium { background-image: url('../../assets/dark-theme/icons/medium.png'); }
    .linkedin { background-image: url('../../assets/dark-theme/icons/linkedin.png'); }
    .twitter { background-image: url('../../assets/dark-theme/icons/twitter.png'); }
    .youtube { background-image: url('../../assets/dark-theme/icons/youtube.png'); }
  }
}