@import '../../style-mixins.scss';

.social {
  position: relative;
  width: 100%;

  &-icon {
    background-size: contain;
    width: 35px;
    height: 35px;
    margin: 5px;

    @media (hover: hover) {
      &:hover {
        opacity: 0.75;
      }
    }
  }

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &--theme-light {
    @extend .social;

    .github { background-image: url('../../assets/light-theme/icons/github.png'); }
    .instagram { background-image: url('../../assets/light-theme/icons/instagram.png'); }
    .medium { background-image: url('../../assets/light-theme/icons/medium.png'); }
    .linkedin { background-image: url('../../assets/light-theme/icons/linkedin.png'); }
    .twitter { background-image: url('../../assets/light-theme/icons/twitter.png'); }
    .youtube { background-image: url('../../assets/light-theme/icons/youtube.png'); }
  }

  &--theme-dark {
    @extend .social;

    .github { background-image: url('../../assets/dark-theme/icons/github.png'); }
    .instagram { background-image: url('../../assets/dark-theme/icons/instagram.png'); }
    .medium { background-image: url('../../assets/dark-theme/icons/medium.png'); }
    .linkedin { background-image: url('../../assets/dark-theme/icons/linkedin.png'); }
    .twitter { background-image: url('../../assets/dark-theme/icons/twitter.png'); }
    .youtube { background-image: url('../../assets/dark-theme/icons/youtube.png'); }
  }
}