@import '../../style-mixins.scss';

.header {
  $mainHeaderSize: 3rem;
  font-size: $mainHeaderSize;

  @media (orientation: portrait), (max-width: $appBreakpoint) {
    font-size: $mainHeaderSize * .83;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
  }

  h2 {
    font-size: 0.5em;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;

    &::before {
      content: "@";
      opacity: 0;
      font-size: $mainHeaderSize;
      line-height: 0;

      @media (orientation: portrait), (max-width: $appBreakpoint) {
        display: none;
      }
    }

    .Typewriter {
      display: inline-flex;
      align-items: center;

      &__cursor {
        font-size: 1.5em;
      }
    }
  }
}