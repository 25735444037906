@import '../../style-mixins.scss';

.theme {

  &--light {
    @extend %theme-container;
    color: theme-color('light', 'color');

    .theme-background {
      background-color: theme-color('light', 'background-color');

      &-image-container {
        @extend %image-container;
        background-image: url('../../assets/light-theme/bg-selfie.jpg');
      }
    }

    .theme-content-mobile-image-container {
      background-image: url('../../assets/light-theme/bg-selfie.jpg')
    }

    .theme-content-posts::after {
      background-image: url('../../assets/light-theme/logo512.png');
    }
  }

  &--dark {
    @extend %theme-container;
    color: theme-color('dark', 'color');

    .theme-background {
      background-color: theme-color('dark', 'background-color');

      &-image-container {
        @extend %image-container;
        background-image: url('../../assets/dark-theme/bg-selfie.jpg');
      }
    }

    .theme-content-mobile-image-container {
      background-image: url('../../assets/dark-theme/bg-selfie.jpg')
    }

    .theme-content-posts::after {
      background-image: url('../../assets/dark-theme/logo512.png');
    }
  }

  &-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
  }

  &-container {
    @extend %app-container;

    .theme-background & {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &-content {
    align-items: flex-end;

    @media (orientation: portrait), (max-width: $appBreakpoint) {
      align-items: center;
    }

    &-container {
      max-width: 450px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-header {
      height: 85px;
    }

    &-footer {
      margin-top: 20px;
      flex: auto;
      font-size: 0.8em;
      display: flex;
      justify-content: flex-end;

      &-info {
        align-self: flex-end;
        a {
          font-weight: 700;
        }
      }
    }

    &-social {
      position: relative;
      margin-top: 20px;
      height: 45px;
      transition: margin-top $appThemeTransitionTime;

      @media (orientation: portrait), (max-width: $appBreakpoint) {
        margin-top: 10px;
      }
    }

    &-posts {
      position: relative;
      margin-top: 20px;
      width: 450px;
      max-width: calc(100vw - 20px);

      &::after {
        display: inline-block;
        margin: 30px 0;
        content: "";
        width: 100%;
        height: 65px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &-mobile {
      $mobileBackgroundHeight: 300px;

      position: relative;
      height: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
      opacity: 0;
      overflow: hidden;
      transition: height $appThemeTransitionTime, 
        margin-top $appThemeTransitionTime,
        margin-bottom $appThemeTransitionTime, 
        opacity $appThemeTransitionTime;

      &-image-container {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: $mobileBackgroundHeight;
      }

      @media (orientation: portrait), (max-width: $appBreakpoint) {
        height: $mobileBackgroundHeight;
        margin-bottom: $mobileBackgroundHeight * -0.2;
        margin-top: 20px;
        opacity: 1;
      }
    }
  }
}

%theme-container {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
}

%image-container {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity $appThemeTransitionTime;

  @media (orientation: portrait), (max-width: $appBreakpoint) {
    opacity: 0;
  }
}