@import '../../style-mixins.scss';

$buttonHeight: 35px;
$buttonSliderWidth: 90px;
$buttonShrinkSize: 6px;

.theme-toggle {
  $themeLightState: ".theme-toggle--state-light";
  $themeDarkState: ".theme-toggle--state-dark";

  cursor: pointer;
  position: relative;
  font-size: 0.8rem;
  text-transform: uppercase;
  width: $buttonSliderWidth;
  height: $buttonHeight;
  border-radius: 20px;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color $appThemeTransitionTime;
  z-index: 5;

  &:focus {
    outline: none;
  }

  &--state-light {
    color: theme-color('light','slider-color');
    background-color: theme-color('light','slider-background-color');
  }

  &--state-dark {
    color: theme-color('dark','slider-color');
    background-color: theme-color('dark','slider-background-color');
  }

  &-button {
    position: absolute;
    border-radius: $buttonHeight;
    height: calc(#{$buttonHeight} - #{$buttonShrinkSize});
    width: calc(#{$buttonHeight} - #{$buttonShrinkSize});
    transition: left $appThemeTransitionTime, background-color $appThemeTransitionTime;

    #{$themeLightState} & {
      top: calc(#{$buttonShrinkSize} / 2);
      left: calc(#{$buttonShrinkSize} / 2);
      background-color: theme-color('light','background-color');
    }

    #{$themeDarkState} & {
      top: calc(#{$buttonShrinkSize} / 2);
      left: calc((#{$buttonSliderWidth} - #{$buttonHeight}) + (#{$buttonShrinkSize} / 2));
      background-color: theme-color('dark','background-color');
    }
  }

  &-text {
    display: inline-block;

    #{$themeLightState} & {
      padding-left: 10px;
    }

    #{$themeDarkState} & {
      padding-right: 20px;
    }    
  }
}
